import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/wcenatus/Documents/GitHub/cwa-site/src/templates/MdxLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "http://www.paymentssource.com/news/paythink/btob-commerce-tech-is-finally-catching-up-to-consmer-payments-3022558-1.html?utm_medium=email&ET=paymentssource:e5383772:4342130a:&utm_source=newsletter&utm_campaign=iso%20weekly-oct%2022%202015&st=email"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "300px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMCBAX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHTjdBY0P/EABkQAAMBAQEAAAAAAAAAAAAAAAECAxMQEf/aAAgBAQABBQJ6t7o+kiTPv//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABoQAAICAwAAAAAAAAAAAAAAAAECADEgQWL/2gAIAQEABj8CIVanMBa94f/EABwQAAIDAAMBAAAAAAAAAAAAAAABESFBUXHB0f/aAAgBAQABPyFZrdlL2voqj223GQvToxuCCLFR/9oADAMBAAIAAwAAABDAD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABwQAQADAAIDAAAAAAAAAAAAAAEAESExQWFxkf/aAAgBAQABPxBE5k8WyjsaF+4AjghnjF+W/IOgMaChXxEtu7KamAT/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "evolve-business-phone-ss-ps",
            "title": "evolve-business-phone-ss-ps",
            "src": "/static/fd551664d45a9392930b69151587d7c9/8fc94/evolve-business-phone-ss-ps-300x192.jpg",
            "srcSet": ["/static/fd551664d45a9392930b69151587d7c9/8675c/evolve-business-phone-ss-ps-300x192.jpg 100w", "/static/fd551664d45a9392930b69151587d7c9/3cc80/evolve-business-phone-ss-ps-300x192.jpg 200w", "/static/fd551664d45a9392930b69151587d7c9/8fc94/evolve-business-phone-ss-ps-300x192.jpg 300w"],
            "sizes": "(max-width: 300px) 100vw, 300px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p>{`We used to be content just searching online for information on products, scanning the latest news or finding out what our friends and family were up to.`}</p>
    <p>{`But today we expect consumer e-commerce and payment sites to be intelligent and deliver experiences that are personal and relevant, and to know things about us and anticipate our needs within the context of our actions and to execute them in line with our personal preferences and company policies. To help us not only do things faster, but better. After lagging consumer payments, business to business payments is finally catching up to that level of function.`}</p>
    <p>{`Technology has unleashed a host of new data. And by mining this data for insights and delivering them to the right people at the right times, technology can make us more intelligent and ultimately drive advantage.`}</p>
    <p>{`Business networks are turning these scenarios into realities for the procurement industry. By leveraging the hundreds of billions of dollars of financial transactions and transactional data along with relationship history that resides in business networks, for instance, buyers and sellers can make more informed decisions by detecting changes in buying patterns or pricing trends. This provides organizations with more confidence and qualifying information on a potential, yet unfamiliar, trading partner.`}</p>
    <p>{`And, when combined with community-generated ratings and content, they can glean not only real-time insights, but also recommended strategies for moving their businesses forward. So, things like performance ratings where buyers rate suppliers and suppliers rate buyers. Others in the community can use this information to help detect risk in their supply chain or determine who to do business with – much like a consumer might read a product review before purchasing something online.`}</p>
    <p>{`For any questions regarding this article, please contact CWA Merchant Support at (866) 210-4625 X1 or email `}<a parentName="p" {...{
        "href": "mailto:merchantsupport@cwams.com"
      }}>{`merchantsupport@cwams.com`}</a>{`.`}</p>
    <p>{`To continue reading, please go to `}<a parentName="p" {...{
        "href": "http://www.paymentssource.com/news/paythink/btob-commerce-tech-is-finally-catching-up-to-consmer-payments-3022558-1.html?utm_medium=email&ET=paymentssource:e5383772:4342130a:&utm_source=newsletter&utm_campaign=iso%20weekly-oct%2022%202015&st=email"
      }}>{`PAYMENT SOURCE`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      